import { useState, SyntheticEvent, FormEvent, Fragment } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { useAuth } from "context/AuthContext";
import { toast } from "react-toastify";
import BasicToast from "components/common/Layout/BasicToast";
import { ErrorWithCode } from "models/ErrorWithCode";
import LinkToast, { showLinkToast } from "components/common/Layout/LinkToast";
import { UserCredential } from "@firebase/auth";
import { TOAST_IDS } from "constants/toastConstants";
import { addToWaitlist } from "pages/api/user";
import { ERROR_CODES } from "constants/errorCodes";
import { FirebaseError } from "firebase/app";
import { BASE_URL } from "constants/redirects";
import { subscribeToUser } from "pages/api/subscription";
import LoginPopupContent from "./LoginPopupContent";

interface RegistrationPopupProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  userProfileId?: string;
  handleSubscribe?: (registerUid: string) => void;
}

function RegistrationPopup({
  open,
  setOpen,
  userProfileId,
  handleSubscribe,
}: RegistrationPopupProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [shouldRememberCheckbox, setShouldRememberCheckbox] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showLoginContent, setShowLoginContent] = useState(false);

  const {
    registerWithEmail,
    registerWithGoogle,
    getGoogleCredentials,
    setShouldRemember,
    logout,
  } = useAuth();
  const router = useRouter();

  const handleRegistration = async (
    registration: Promise<UserCredential>,
    credentials?: UserCredential | null
  ) => {
    return registration
      .then(async () => {
        if (credentials?.user?.uid && userProfileId) {
          handleSubscribe(credentials?.user?.uid);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch(async (err) => {
        // WAITLIST DISABLED
        // if (err instanceof ErrorWithCode) {
        //   if (err.code === ERROR_CODES.alreadyRegistered) {
        //     showLinkToast(
        //       "error",
        //       "Account already exists. Click here to login instead.",
        //       `${BASE_URL}/login}`
        //     );

        //     setIsLoading(false);
        //     return;
        //   }

        //   const waitlistUser = await addToWaitlist(email);

        //   toast.success(
        //     `You're on the waitlist! Position: ${waitlistUser.priority}`,
        //     {
        //       containerId: TOAST_IDS.basicToastId,
        //     }
        //   );

        //   setIsLoading(false);
        //   return;
        // }

        if (err instanceof ErrorWithCode) {
          if (err.code === ERROR_CODES.alreadyRegistered) {
            showLinkToast(
              "info",
              "Account already exists. Click here to login instead.",
              `${BASE_URL}/login`
            );

            setIsLoading(false);
            return;
          }
        }

        console.error(err);
        toast.error("Something went wrong", {
          containerId: TOAST_IDS.basicToastId,
        });
        setIsLoading(false);
      });
  };

  const handleSubmitEmail = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLoading) return;
    setIsLoading(true);

    if (!password || password.length < 8) {
      toast.error("Password should contain at least 8 characters.", {
        containerId: TOAST_IDS.basicToastId,
      });
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.", {
        containerId: TOAST_IDS.basicToastId,
      });
      setIsLoading(false);
      return;
    }

    setShouldRemember(shouldRememberCheckbox);
    await handleRegistration(registerWithEmail(email, password));
  };

  const handleGmailLogin = async () => {
    if (isLoading) return;
    setIsLoading(true);

    setShouldRemember(shouldRememberCheckbox);
    const credentials = await getGoogleCredentials();
    await handleRegistration(registerWithGoogle(credentials), credentials);
  };

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) =>
    setEmail(e.currentTarget.value);

  const handlePasswordChange = (e: FormEvent<HTMLInputElement>) =>
    setPassword(e.currentTarget.value);

  const handleConfirmPasswordChange = (e: FormEvent<HTMLInputElement>) =>
    setConfirmPassword(e.currentTarget.value);

  const handleShouldRememberChange = (e: FormEvent<HTMLInputElement>) =>
    setShouldRememberCheckbox(e.currentTarget.checked);

  const hasEmailAndPassword = () => email && password && confirmPassword;

  const handleCloseModal = () => setOpen(false);

  const handleLoginClick = () => setShowLoginContent(true);
  const handleRegisterClick = () => setShowLoginContent(false);

  const renderRegisterOrLogin = () => {
    if (showLoginContent) {
      return (
        <LoginPopupContent
          userProfileId={userProfileId}
          handleSubscribe={handleSubscribe}
          handleRegisterClick={handleRegisterClick}
        />
      );
    }

    return (
      <div className="mt-14 flex min-h-full w-full flex-col items-center justify-center overflow-y-auto py-12 sm:px-6 lg:px-8">
        <div className="mt-24 sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 rounded-md px-4 py-2 text-center text-2xl font-light tracking-tight text-black">
            To get started, create a free account.
          </h2>
          <div className="text-center text-xs">
            Upgrade for posting tools at $23 a month.
          </div>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmitEmail}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                    onChange={handleEmailChange}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                    onChange={handlePasswordChange}
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                    onChange={handleConfirmPasswordChange}
                    onPaste={handlePasswordChange}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-500"
                    onChange={handleShouldRememberChange}
                    onPaste={handleConfirmPasswordChange}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <div className="block text-sm font-normal text-gray-400">
                By creating an account, you accept Starlo&apos;s{" "}
                <Link href="/privacy" className="underline" target={"_blank"}>
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link
                  href="/terms-and-conditions"
                  className="underline"
                  target={"_blank"}
                >
                  Terms & Conditions
                </Link>
                .
              </div>

              <div>
                <button
                  type="submit"
                  disabled={!hasEmailAndPassword() || isLoading}
                  className="flex w-full justify-center rounded-md border border-transparent bg-orange-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none  focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-orange-500 disabled:opacity-70"
                >
                  {`${
                    isLoading
                      ? "Creating your account..."
                      : "Create your account and subscribe"
                  }`}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-3">
                <div>
                  <button
                    disabled={isLoading}
                    onClick={handleGmailLogin}
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 "
                  >
                    <span className="sr-only">Sign in with Google</span>
                    <svg
                      className="h-5 w-5 text-gray-500"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M17.788 5.108A9 9 0 1021 12h-8" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="text-center text-xs text-gray-400">
            Or{" "}
            <button
              className="cursor-pointer underline"
              type="button"
              onClick={handleLoginClick}
            >
              login
            </button>{" "}
            to an existing account
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 ">
          <div
            className={`flex  min-h-full w-screen items-center justify-center  text-center sm:items-center sm:p-10`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative  flex h-full max-h-screen w-full max-w-3xl transform flex-col items-center justify-start  overflow-hidden rounded-lg   bg-white text-left shadow-xl transition-all sm:max-h-[80vh] `}
              >
                <div className="relative flex w-full overflow-hidden">
                  <div className="fixed top-2 flex w-full max-w-3xl flex-row justify-between bg-white">
                    <div className="flex flex-row items-center">
                      <button
                        type="button"
                        className="ml-2 inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        onClick={handleCloseModal}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  {renderRegisterOrLogin()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default RegistrationPopup;
